<template>
  <div>
    <chevron-down-button
      @click="showMenuParents"
      tooltip="Show Parents"
    ></chevron-down-button>

    <!-- Menu Parents -->
    <v-menu v-model="visibleMenuParents" :position-x="x" :position-y="y">
      <!-- parents (breadcrumbs) -->
      <template v-slot:default>
        <base-nav-list>
          <base-menu-item
            v-for="bc in computedBreadcrumbs"
            :key="bc.id"
            :disabled="!canNavigate(bc.id)"
            :to="createTargetRoute(bc.id)"
          >
            <!-- Record Icon -->
            <template v-slot:icon>
              <record-badge-icon
                :record="bc"
                :disabled="!canExpandRecord(bc)"
              ></record-badge-icon>
            </template>
            {{ bc.name }}
          </base-menu-item>
        </base-nav-list>
      </template>
    </v-menu>
  </div>
</template>

<script>
import { itemCount } from "@/utils";

export default {
  name: "ChevronDownButtonParents",
  components: {
    ChevronDownButton: () =>
      import("@/components/shared/core/buttons/ChevronDownButton"),
    BaseNavList: () => import("@/components/shared/base/BaseNavList"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem"),
    RecordBadgeIcon: () => import("@/components/record/RecordBadgeIcon")
  },
  data() {
    return {
      visibleMenuParents: false,
      x: 0,
      y: 0
    };
  },
  props: {
    /**
     * array of breadcrumbs
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    breadcrumbs: undefined,

    /**
     * Callback function: canNavigate(id)
     */
    canNavigate: {
      type: Function,
      default: undefined,
      required: true
    },

    /**
     * Callback function: createTargetRoute(id)
     */
    createTargetRoute: {
      type: Function,
      default: undefined,
      required: true
    }
  },
  computed: {
    /**
     * computed Breadcrumbs
     * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}[]}
     */
    computedBreadcrumbs() {
      return this.breadcrumbs ?? [];
    },

    /**
     * last Breadcrumb
     * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}|undefined}
     */
    lastBreadcrumb() {
      return this.breadcrumbCount > 0
        ? this.breadcrumbs[this.breadcrumbCount - 1]
        : undefined;
    },

    /**
     * breadcrumb Count
     * @return {Number|number}
     */
    breadcrumbCount() {
      return itemCount(this.breadcrumbs);
    },

    /**
     * last Breadcrumb id @return {*|number}
     */
    lastBreadcrumbId() {
      return this.lastBreadcrumb?.id ?? -1;
    }
  },
  methods: {
    /**
     * show Menu Parents
     * @param e event argument
     */
    showMenuParents(e) {
      e?.preventDefault();

      this.visibleMenuParents = false;

      this.x = e?.clientX ?? 0;
      this.y = e?.clientY ?? 0;

      this.$nextTick(() => {
        this.visibleMenuParents = true;
      });
    },

    /**
     * can Expand Record
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} breadcrumb
     * @return {boolean} true if breadcrumb can Expand
     */
    canExpandRecord(breadcrumb) {
      return breadcrumb && !(breadcrumb.id === this.lastBreadcrumbId);
    }
  }
};
</script>
